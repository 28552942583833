
.font-raleway {
    font-family: 'Raleway', 'serif';
    font-style: normal;
    font-weight: 400;
}

.font-raleway.black, .font-raleway .black {
    font-family: 'Raleway', 'serif';
    font-style: normal;
    font-weight: 900;
}

.font-raleway.bold, .font-raleway .bold {
    font-family: 'Raleway', 'serif';
    font-style: normal;
    font-weight: 700;
}

.material-icons {
    cursor: pointer;
    position: relative;
    font-family: "Material Icons";
    font-size: 1.5em !important;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

