$font_family_1: sans-serif;
$yellow_color: #dfcd33;
$blue_color: #12629f;

@import "animations";
@import "tools";
@import "language-chooser";
@import "menu";

li, p {
  & > span {
    &:last-of-type {
      font-size: 1.5rem;
    }
  }
}

.title-text {
  h1 {
    @media all and (max-width: 700px) {
      font-size: 20px;
      margin: -30px;
    }
  }
}

.blogPost, .product {
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  background: white;
  padding: 20px;
  @media all and (min-width: 800px) {
    margin-left: -10%;
    width: 120%;
    padding: 20px 75px;
  }

  img {
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #74651d24;
  }
}

.rendered-html {
  img {
    max-width: 100%;
  }
}

.actions {
  position: absolute;
  top: -30px;
  left: 0;
  margin-top: 15px;

  &.product-actions {
    position: relative;
    top: 0;
    left: 10px;
  }

  &.product-image-actions {
    position: relative;
    top: 0;
    left: 0;
    padding: 10px 0;
  }

  i {
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 2px 8px;
    border: 1px solid #80808063;
    border-radius: 5px;
    background: white;

    &.delete {
      margin: 3px;
      background: darkred;
      color: white;
    }
  }
}

.mail-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);

  &.active {
    width: 100%;
    height: 100%;
  }

  .close-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: block;
    border: 1px solid white;
    padding: 7px;
    background: white;
    color: black;
    border-radius: 5px;
    font-weight: bold;
  }

  .mail-button {
    position: fixed;
    left: 10px;
    bottom: 10px;
    display: block;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background: #2f2e2e;
    color: white;

    &.active {
      background: green;
    }

    i {
      margin-right: 3px;
    }
  }

  .mail-holder {
    height: 80%;
    display: none;
    background: white;
    width: 98%;
    margin: auto;
    border-radius: 5px;
    padding: 20px;
    top: 10%;
    position: relative;
    overflow: hidden;

    > h3 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;

      > b {
        display: flex;
        align-items: center;
      }

      > div {
        > button {
          background: #eee;
          border: 1px solid #aaa;
          padding: 6px;
          font-size: 16px;
          border-radius: 2px;
          text-transform: uppercase;

          &.active {
            color: white;
            background: #5a5a5a;
          }
        }
      }
    }

    table {
      border: 1px solid #aaa;
      width: 220px;
      margin-right: 10px;
      overflow-y: auto;
      height: 90%;
      display: inline-block;

      td {
        padding: 5px;
        border-bottom: 1px solid #aaa;

        > div {
          &.read {
            background: #eee;
          }

          width: 205px;
          background: #c3ffd8;
          padding: 6px;
          cursor: pointer;
          border-radius: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          transition-duration: .2s;

          &:hover, &.active {
            background: #4b4b4b;
            color: white;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }

        h6, p {
          margin: 0;
        }

      }
    }

    .mail-content {
      overflow-y: auto;
      width: calc(100% - 250px);
      border: 1px solid #aaa;
      height: 90%;
      vertical-align: top;
      display: inline-block;
      padding: 10px;
      font-size: 18px;

      > p {
        max-height: 300px;
        overflow: auto;
      }
    }
  }
}

.ReactTable {
  border-radius: 7px;
  background: white;

  .-pagination {
    .-next {
      .-btn {
        font-weight: bold;
        background: #ad8d00;
        color: white;
      }
    }

    .-previous {
      .-btn {
        font-weight: bold;
        background: rgba(145, 153, 134, 0.74);
        color: white;
      }
    }
  }

  .rt-tbody {
    min-width: 850px !important;

    .rt-tr-group {
      &:nth-child(even) {
        background-color: #fbfbfb;
      }
    }
  }

  .rt-thead.-header {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    background: #f8f9ee;

    .rt-tr {
      .rt-th {
        font-weight: bold;
        padding: 12px;
      }
    }
  }
}

.toggler {
  .checkbox {
    display: none;
  }

  .label {
    outline: none;
    position: relative;
    width: 3rem;
    height: 2rem;
    background-color: #640601;
    display: inline-block;
    font-size: 0;
    border-radius: 2rem;
    transition: background-color 0.2s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      height: 1.5rem;
      width: 1.5rem;
      background-color: lightgray;
      border-radius: 50%;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in-out;
    }
  }

  .checkbox:checked {

    + .label {
      background-color: green;

      &::before {
        transform: translate3d(1rem, 0, 0);
      }
    }
  }
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.hidden_span {
  display: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.pagination {
  list-style-type: none;
  text-align: right;

  li {
    cursor: pointer;
    background: white;
    display: inline-block;
    padding: 5px 12px;
    border: 1px solid #aaa;
    border-radius: 3px;
    margin: 3px;

    &.active {
      font-weight: bold;
      background: #5a5a5a;
      color: white;
    }
  }
}

.status {
  div {
    margin: 10px;
    width: 100%;
    background: #848484;
    padding: 10px;
    border-radius: 7px;
    color: white;
    font-family: monospace;
    font-size: 18px;

    &.success {
      background: darkgreen;
    }

    &.valid, &.error {
      background: #f6e9e8;
      color: #222;
      font-size: 15px;
      padding: 5px;
      border: 1px solid #d09f7d;
      border-radius: 0;
      font-family: inherit;
      text-align: center;
      font-weight: bold;
    }
  }
}

.card {
  background: #bebebe1f;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #80808063;
  position: relative;

  .img {
    background-repeat: no-repeat;
    background-position: center;
    width: auto;
    height: 200px;
    background-size: contain;
    background-color: white;
  }

  p {
    width: 100%;
  }
}

.admin {

  .quill {
    border: 1px solid #222 !important;
    border-radius: 5px !important;
  }

  .admin-panel {
    text-align: right;

    input, select {
      padding: 10px 20px;
      font-size: 18px;
      border: 1px solid #222;
      border-radius: 5px;
      background: #fff;
      color: #222;
      outline: none;
      width: 100%;
    }

    .admin-button {
      cursor: pointer;
      padding: 10px 20px;
      font-size: 18px;
      border: none;
      font-family: monospace;
      border-radius: 5px;
      background: #222;
      color: white;
      outline: none;
      transition-duration: .3s;

      &[disabled] {
        cursor: not-allowed;
        opacity: .4;
      }

      &:not(.admin-button[disabled]):hover {
        opacity: .8;
      }
    }
  }
}

.cats {
  justify-content: center;

  .c-1-2 {
    a {
      cursor: default;
      position: relative;
      display: block;
      margin-bottom: 36px;
      background: #000000cf;
      transition-duration: .3s;

      i {
        position: absolute;
        color: white;
        font-size: 12px;
        z-index: 1;
        bottom: 2px;
        width: 100%;
        text-align: center;
      }

      span {
        font-size: 23px;
        position: absolute;
        z-index: 9;
        top: 50%;
        right: 0;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        text-transform: uppercase;
        color: white;
      }

      img {
        display: block;
        width: 100%;
        transition: all .3s ease-in-out;
        -webkit-transform: rotateZ(0);
        opacity: .5;
        -webkit-backface-visibility: hidden;
      }
    }
  }
}

.main-container {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: $font_family_1;
  /* user-select: none; */
  font-size: 1em;
}

#root {
  .splash {
    &.loading-done {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    z-index: 200;
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    margin: 0;
    position: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px auto;

  }

  .loading-container {
    z-index: 200;
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    margin: 0;
    position: relative;

  }
}

.test-data {
  margin-top: 60px;
}

.footer {
  color: #d5d5d5;
  background: #2f2e2e;
  padding: 70px 100px 100px;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;

  li {
    span {
      font-size: inherit !important;
    }
  }

  @media all and (max-width: 570px) {
    padding: 50px 50px 100px;
  }

  .material-icons {
    color: #fed200;
    font-size: 20px !important;
    vertical-align: middle;
  }

  .footer-title {
    position: relative;
    z-index: 3;
    height: 10px;
    text-transform: uppercase;
  }

  .contactus, .links {
    width: 250px;
    margin-right: 80px;
    display: inline-block;

    ul {
      padding: 0;
      list-style-type: none;
    }
  }

  .copyright {
    color: #fff;
    position: absolute;
    bottom: 10px;
    right: 6px;
    width: 240px;
    height: 40px;

    span {
      margin-right: 5px;
    }

    img {
      height: 35px;
      color: black;
      text-transform: uppercase;
    }
  }
}

.title {
  position: relative;
  z-index: 3;
  text-align: center;

  .title-text {
    text-transform: uppercase;
    text-shadow: 0 0 5px white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin: 0 10px 40px;

    .bot-border {
      background-color: $blue_color;
      color: $blue_color;
      width: 44px;
      height: 6px;

      &.yellow {
        background-color: $yellow_color !important;
        color: $yellow_color !important;
      }
    }

  }

}

@media all and (max-width: 700px) {
  .bg-white.bg-top {
    display: none;
  }
}

.about {
  @media all and (min-width: 768px) {
    .bg-white {
      background: white;
      padding: 80px;
      border: 1px solid #eee;

      &.bg-top {
        padding: 0;
        border: none;

        & ~ .bg-white {
          border-top: none;
          margin-top: -30px;
        }
      }
    }
  }

  .staff {
    margin-top: 20px;
    padding: 0;

    img {
      width: 98%;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 #74651d24;
    }
  }

  .mot {
    margin-bottom: 60px;
    text-align: center;
    .img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin: 0 auto;
      box-shadow: 0 1px 4px -2px #00000024;
    }
    .text {
      padding: 0 10px;
      h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
      }
      & > span {
        text-align: justify-all;
      }
      .position {
        margin-bottom: 2rem;
        color: black;
        display: block;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  p, li {
    font-size: 18px;
    margin-top: 10px;
  }
}

.contact-form {
  .c-1-1, .c-1-2 {
    padding: 5px;
  }

  input, textarea, button {
    outline: none;
    width: 100%;
    background: white;
    padding: 10px;
    border: 1px solid #222;
  }

  button {

    &[disabled] {
      cursor: not-allowed;
      opacity: .4;

      &:hover {
        opacity: .4;
      }
    }

    background: #222;
    color: white;
    border: none;
    transition-duration: .3s;

    &:hover {
      opacity: .8;
    }
  }

  textarea {
    min-height: 150px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.62);
  z-index: 200;
  width: 100%;
  height: 100%;
  margin: 0;

  label {
    top: 45px;
    position: absolute;
    color: white;
    right: 10%;
    @media all and (min-width: 800px) {
      right: 25%;
    }
  }

  .modal-content {
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    background: white;
    left: 10%;
    position: relative;
    top: 70px;
    @media all and (min-width: 800px) {
      width: 50%;
      left: 25%;
    }

    input, button {
      padding: 5px;
      border-radius: 3px;
      display: block;
      width: 90%;
      margin: auto;
      border: 1px solid #ccc;
      background: white;
    }
  }
}

.quill {
  background: white;
}

.navigation {
  position: fixed;
  bottom: 0;
  right: 0;
  color: black;
  margin: 8px;
  width: 190px;
  z-index: 99;

  input, button {
    margin: 5px;
    padding: 5px;
    border-radius: 3px;
    display: block;
    width: 90%;
    border: none;
    background: red;
    color: white;
    font-weight: bold;
  }
}

.contact-holder {
  height: 200px;
  text-align: center;
  font-size: 2rem;
  background: #fed200;
  color: black;
  padding: 5rem 1rem;
  font-weight: bold;

  a {
    img {
      margin: 0 !important;
      height: 40px !important;
      width: auto !important;
    }
  }

  i {
    display: block;
  }

}

.services {
  p {
    font-size: 20px;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 #00000030;
    background: white;
    color: #000;

    &:last-of-type {
      margin-top: 23px;
      background: #2c2a2b;
      color: #fed200;
    }
  }
}

.contact {
  img {
    width: 84%;
    margin-left: 10%;
  }
}

.kaem {
  ul {
    padding: 0;
    list-style-type: none;
    position: relative;
    li {
      position: relative;
      font-family: monospace;
      background: #d6b30b1f;
      padding: 5px;
      font-size: 0.9em;
      border-radius: 2px;

    }
  }
}