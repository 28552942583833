@keyframes toggle_opacity {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes toggle_opacity_up {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.loading_anim {
  width: 110px;
  background: rgb(128, 128, 128);
  height: 16px;
  border-radius: 50px;
  transition-duration: 1s;
  animation: toggle_opacity 2s linear infinite;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes shake {

  0%, 100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }

}

.shake {
  -moz-animation: shake 0.7s infinite;
  -webkit-animation: shake 0.7s infinite;
  animation: shake 0.7s infinite;
}

