@media all and (max-width: 1023px) {

  .menu {
    overflow: hidden;

    .menu-items {

      height: calc(100vh - 110px);
    }

    .menu-items {
      background: white;
      position: absolute;
      top: 85px;
      border-radius: 3px;
      margin: 0;
      padding: 15px;
      text-align: center;
      height: calc(100vh - 200px);
      width: 100% !important;

      overflow-x: hidden;
      overflow-y: auto;

      ul {
        flex-direction: column !important;

        height: auto !important;
        margin: 0 10px;

        .after_cursor {
          display: none;
        }

        li {
          padding: 10px;
          height: auto !important;

          &:hover > ul.sub_menu {
            top: 0 !important;
          }

          &.active > .after_cursor {
            opacity: 1 !important;

            &.not_sub {
              top: 58px !important;
              height: 5px !important;
            }

            &.is_sub {
              left: 0 !important;
              width: 5px !important;
            }
          }

          ul.sub_menu {
            opacity: 1 !important;
            margin: 0;
            visibility: visible !important;
            width: 100% !important;
            position: relative !important;

            padding: 0 !important;
            top: 0 !important;
            background: transparent;
            box-shadow: none !important;

            li {
              font-size: 12px !important;
              color: $blue_color;
              height: 40px;
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              align-content: center;

              &:first-of-type {
                padding-top: 20px !important;
              }
            }
          }
        }
      }

    }

    .ham {
      display: block !important;

      ~ .menu-items {
        background: transparent !important;
      }
    }

  }
}

.menu {
  z-index: 180;

  &.fixed {
    height: 110px;


    & ~ .carousel {
      top: 108px;
    }

    .menu-holder {
      .menu-items {
        margin-top: 11px;

        ul li {

          &:hover > .after_cursor.not_sub,
          &.active > .after_cursor.not_sub {
            top: 47px !important;
          }
        }
      }

      .logo {
        img {
          height: 60px;
          @media all and (min-width: 700px) {
            height: 100px !important;
          }
        }
      }
    }
  }

  top: 0;
  height: 144px;
  width: 100%;
  left: 0;
  padding: 10px 10px 0;
  position: fixed;
  transition-duration: .2s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0 1px 5px 0 #00000059;
  -moz-box-shadow: 0 1px 5px 0 #00000059;
  box-shadow: 0 1px 5px 0 #00000059;

  top: 0;
  border-radius: 0;
  left: 0;
  width: 100%;

  &.menu_active {
    height: 100% !important;

    .menu-items {
      box-shadow: none !important;
    }
  }

  &::before {
    content: '';
    background-color: white;
    position: absolute;
    top: -8px;
    width: 100%;
    height: 60px;
    left: 0;
    opacity: .2;
    border-radius: 2px;
  }

  .loading-bar {
    content: '';
    background-color: $yellow_color;
    position: absolute;
    bottom: 0;
    width: 30%;
    transition-duration: .2s;
    height: 3px;
    left: 0;
    opacity: 0;
    border-radius: 2px;
  }

  .menu-holder {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    top: 3px;

    .ham {
      height: 100px;
      margin: 12px 10px 0;
      color: $blue_color;
      cursor: pointer;
      display: none;

      i {
        font-size: 30px !important;
        margin: 10px !important;
      }
    }

    .logo {
      height: 100px;
      margin: 14px 0;

      img {
        transition-duration: .2s;
        margin-left: 20px;
        margin-right: 20px;
        @media all and (min-width: 700px) {
          height: 130px !important;
          margin-top: -20px;
        }
      }
    }

    .menu-items {
      margin-top: 58px;

      ul {
        margin: 0 20px -13px;
        list-style-type: none;
        position: relative;
        padding: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        li {
          text-transform: uppercase;
          height: 62px;
          margin: 0 10px;
          cursor: pointer;

          &:hover > ul.sub_menu {
            opacity: 1;
            visibility: visible;
            top: 58px;
          }

          &:hover > .after_cursor {
            opacity: 1 !important;

            &.not_sub {
              top: 58px !important;
              height: 5px !important;
            }

            &.is_sub {
              left: 0 !important;
              width: 5px !important;
            }
          }

          &.active > .after_cursor {
            opacity: 1 !important;

            &.not_sub {
              top: 58px !important;
              height: 5px !important;
            }

            &.is_sub {
              left: 0 !important;
              width: 5px !important;
            }
          }

          .after_cursor {
            opacity: 0;
            background-color: $yellow_color;
            position: absolute;
            margin: 0;
            -webkit-transition-duration: .2s;
            -o-transition-duration: .2s;
            transition-duration: .2s;

            &.not_sub {
              height: 0;
              top: 58px;
            }

            &.is_sub {
              left: -5px;
              width: 0;
            }

          }

          .sub_menu {
            opacity: 0;
            margin: 0;
            visibility: hidden;
            transition-duration: .2s;
            box-shadow: 0 1px 0 0 #00000026;
            width: 260px;
            z-index: 5;
            border-radius: 2px;
            top: 50px;
            background: white;
            position: absolute;
            padding: 20px;
            list-style-type: none;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;

            li {
              height: 40px;
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              align-content: center;
            }
          }
        }
      }
    }
  }
}

