@media all and (max-width: 901px) {

  .languageChooser {
    top: unset !important;
    right: calc((100vw / 2) - 50px) !important;
    z-index: 190 !important;
    bottom: 20px !important;

    &.hide_lc {
      display: none;
    }
  }
  .language-overlay {
    .languages-holder {
      background: transparent !important;

      .lang-item {
        .flag {
          .text-overlay {
            background: #fff !important;
            color: black !important;
          }
        }
      }
    }
  }
}

.language-overlay {
  .languages-holder {
    padding: 40px;
    background: white;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .lang-item {
      margin: 30px;

      .flag {
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
        cursor: pointer;
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;

        &:hover > .text-overlay {
          opacity: 0.8;
        }

        .text-overlay {
          transition-duration: .2s;
          opacity: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;

          background: #000;
          width: 100px;
          border-radius: 50%;
          height: 100px;
          color: white;
        }
      }
    }
  }

  .close-button {
    color: white;
    position: relative;
    font-size: 30px;
    margin: 0;
    text-align: right;
    padding: 5px;

    i {
      cursor: pointer;
    }
  }
}

/* Language chooser  */
.languageChooser {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  visibility: visible;
  transition-duration: .2s;
  background: white;
  opacity: .9;
  width: 60px;
  text-align: center;
  height: 60px;
  box-shadow: 0 0 3px 1px #00000021;
  padding: 20px;
  cursor: pointer;
  position: fixed;
  right: 10px;
  z-index: 99;
  top: 160px;
}
