$yellow_color: #dfcd33;
$blue_color: #12629f;

@import "tools";
@import "animations";

/* carousel */
.carousel {
  overflow: hidden;
  background-color: darkslategrey;
  position: relative;
  top: 144px;
  left: 0;
  transition-duration: .2s;
  width: 100%;
  height: 380px;

  .carouselCommand {
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -ms-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;

    position: absolute;
    bottom: 15px;
    left: 50px;
    z-index: 3;
    color: #ffeb3b;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }

    i {
      font-size: 35px !important;
    }
  }

  .left-arrow, .right-arrow, .middle-container {
    height: 100%;
    position: absolute;
    display: inline-block
  }

  .carouseLoader {
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -ms-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;

    /* fade effect */
    opacity: 1;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .round {
      position: absolute;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      height: 100px;
      width: 100px;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      border: 2px solid white;
      background: transparent;
      border-top: none;

      -webkit-animation: rotate 4s linear infinite;
      animation: rotate 4s linear infinite;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
    }
  }

  .middle-container {

    .carousel-item {
      position: relative;
      width: 88%;
      left: 6%;
      z-index: 5;
      top: 5%;

      .carousel-item-content {

        &::before {
          content: '';
          background-color: $yellow_color;
          position: absolute;
          top: 10px;
          width: 44px;
          height: 6px;
          left: 10px;
        }

        h1 {
          text-align: left;
          text-shadow: 0 3px 5px #00000094;
          font-size: 50px;
          line-height: 50px;
          color: white;
        }
      }
    }

    background-position: center !important;

    .title {
      color: white;
      overflow-wrap: break-word;
    }

    .name {
      color: wheat;
    }

    .blur-bg {
      height: 300px;
      z-index: 2;
    }

    cursor: default;
    z-index: 1;
    @extend .bg-cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;

    display: inline-block;

    .hover-banner {
      padding: 52px;
      z-index: 2;
      position: relative;
    }
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
  }

  .left-arrow, .right-arrow {
    display: table;
    z-index: 2;
  }

  .left-arrow {
    @extend .gradiant_left;
  }

  .right-arrow {
    @extend .gradiant_right;
  }

  .left-arrow i, .right-arrow i {
    transition-duration: .3s;
    font-size: 40px !important;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    color: white;

    &:hover {
      @extend .shake;
    }
  }

}
