$yellow_color: #dfcd33;

.hide {
  display: none !important;
}

.hide_it {
  visibility: hidden !important;
  z-index: -1 !important;
  position: relative;
}

.center {
  text-align: center;
}

.overlay {
  z-index: 200;
  width: 100%;
  height: 100%;
  background: black;
  top: 0;
  margin: 0;
  position: fixed;
}

.bg-cover {
  background-position: center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.bg-center {
  background-position: center !important;
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  -moz-background-size: 100% auto !important;
  -o-background-size: 100% auto !important;
}

.hideFade {
  opacity: 0 !important;
  z-index: 1 !important;
}

.gradiant_left {
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff', GradientType=1);
}

.gradiant_right {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#000000', GradientType=1);
}
